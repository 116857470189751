<template>
    <div class="x-jx-router" @click="toRouter">
        <div class="gy-zhu"></div>
        <div class="gy-yuan"></div>
        <div :class="['icon', opts.icon]"></div>
        <div class="label">{{opts.title}}</div>
    </div>
</template>
<script>
    import {mergeRouters} from '@/router/index'
    import '@/assets/icon/iconfont.css';

    export default {
        name: 'XGuideRouter',
        data() {
            return {}
        },
        methods: {
            toRouter() {
                if (this.opts.href) {
                    if (this.opts.open) {
                        window.open(this.opts.href);
                    } else {
                        window.location.href = this.opts.href;
                    }
                }
            }
        },
        props: {
            opts: {
                type: Object,
                required: true
            }
        }
    }
</script>
<style lang="scss" scoped></style>
