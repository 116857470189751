<template>
    <div class="x-g-jl">
        <div class="x-g-title">
            {{appName}}
        </div>
        <div class="x-g-content">
            <x-guide-router v-for="r in routeCopys" :opts="r" :key="r.href" :class="r.cls"/>
        </div>
        <div class="x-g-text">
            <div v-for="(r, index) in routeCopys" :key="r.href" @click="toRouter(r)" :class="r.cls" @mouseover="changeTo(index)">
                {{r.title}}
            </div>
        </div>
    </div>
</template>
<script>
    import XGuideRouter from "@/components/x/XGuideRouter"
    import {deepCopy} from "@/util/objects";
    import bjggs from "../util/bjggs";

    export default {
        name: 'GuideJL',
        components: {XGuideRouter},
        data() {
            this.currIndex = 1;
            this.appName = bjggs.APP_NAME
            return {
                routes: deepCopy(this.$store.getters.subsystemRoutes),
                routeCopys: []
            }
        },
        mounted() {
            this.routes.forEach((o, index) => {
                let ss = deepCopy(o);
                ss.cls = this.getClass(index);
                this.routeCopys.push(ss);
            })
            if (this.routes.length === 1) {
                this.currIndex = 0;
            } else {
                this.currIndex = 1;
            }
            this.changeInterval = window.setInterval(() => {
                let next = (this.currIndex === this.routeCopys.length - 1) ? 0 : (this.currIndex + 1);
                this.changeTo(next);
            }, 8888)
        },
        beforeDestroy() {
            window.clearInterval(this.changeInterval);
        },
        methods: {
            getClass(index) {
                switch (index) {
                    case 0:
                        return 'left';
                    case 1:
                        return 'mid';
                    case 2:
                        return 'right';
                    default:
                        return 'other';
                }
            },
            toRouter(opts) {
                if (opts.href) {
                    if (opts.open) {
                        window.open(opts.href);
                    } else {
                        window.location.href = opts.href;
                    }
                }
            },
            getLeftIndex(index) {
                return index === 0 ? (this.routeCopys.length - 1) : (index - 1);
            },
            getRightIndex(index) {
                return index === (this.routeCopys.length - 1) ? 0 : (index + 1);
            },
            changeTo(i) {
                let leftIndex = this.getLeftIndex(this.currIndex);
                let rightIndex = this.getRightIndex(this.currIndex);
                this.routeCopys[this.currIndex].cls = 'other';
                this.routeCopys[leftIndex].cls = 'other';
                this.routeCopys[rightIndex].cls = 'other';

                let leftNext = this.getLeftIndex(i);
                let rightNext = this.getRightIndex(i);
                this.routeCopys[i].cls = 'mid';
                this.routeCopys[leftNext].cls = 'left';
                this.routeCopys[rightNext].cls = 'right';
                this.currIndex = i;
            }
        }
    }
</script>
<style lang="scss" scoped>

</style>
